import React from 'react';

import Layout from '../components/Layout';

const EmergencyPage = () => (
    <Layout fullMenu>
        <article id="main">
            <header>
                <h2>Notfall Kontakte</h2>
            </header>
            <section className="wrapper style5">
                <div className="inner">
                    <h2>Im Notfall</h2>
                    <p>
                        In einer akuten Krisensituation, in der es auf rasche Unterstützung ankommt, finden Sie in
                        Heidelberg Hilfe bei folgenden Anlaufstellen:
                    </p>

                    <h3>Notrufnummer Rettungsdienst 112</h3>

                    <h3>Klinik für Allgemeine Psychiatrie Heidelberg</h3>
                    <p>
                        Psychiatrischen Uniklinik: 06221 - 564 466
                    </p>

                    <h3>Klinik für Allgemeine Innere Medizin und Psychosomatik</h3>
                    <p>
                        Psychosomatische Rufbereitschaft: 0151 - 167 594 23
                    </p>
                </div>
            </section>
        </article>
    </Layout>
);

export default EmergencyPage;
